<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="never">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="客户姓名:">
              <el-input
                v-model="searchFrom.customerName"
                :clearable="true"
                placeholder="请输入客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户电话:">
              <el-input
                v-model="searchFrom.customerPhone"
                :clearable="true"
                placeholder="请输入客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="申请时间:">
              <el-date-picker type="date" placeholder="选择日期" v-model="searchFrom.date1" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="add">添加</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="danger" @click="handleDelete">删除</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <div class="table-block">
      <el-table :data="tableData" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50"></el-table-column>
        <!-- <el-table-column prop="date" label="序号"> </el-table-column> -->
        <el-table-column prop="name" label="客户姓名"> </el-table-column>
        <el-table-column prop="name" label="任务进度"> </el-table-column>
        <el-table-column prop="name" label="客户电话"> </el-table-column>
        <el-table-column prop="province" label="申请时间"> </el-table-column>
        <el-table-column prop="city" label="期望转诊时间"> </el-table-column>
        <el-table-column prop="address" label="完成时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="addNew(scope.row, 2)" type="primary" size="small">操作</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <addReferral v-if="showAddReferral" @closeReferralDialog="closeReferralDialog"></addReferral>
    <editReferral v-if="showEditReferral" @closeEditReferral="closeEditReferral"></editReferral>
  </div>
</template>

<script>
import addReferral from '@/views/appointmentManagement/components/addReferral'
import editReferral from '@/views/appointmentManagement/components/editReferral'
export default {
  data () {
    return {
      searchFrom: {
        customerPhone: '',
        customerName: '',
        date1: ''
      },
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333,
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1517 弄',
          zip: 200333,
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1519 弄',
          zip: 200333,
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1516 弄',
          zip: 200333,
        },
      ],
      multipleSelection: [],
      showAddReferral: false,
      showEditReferral: false
    }
  },
  components: { addReferral, editReferral },
  methods: {
    handleSelectionChange(val) {
      console.log(123)
      this.multipleSelection = val
    },
    add () {
      console.log(123)
      this.showAddReferral = true
    },
    search () {
      console.log(123)
    },
    addNew(){
      console.log(123)
      this.showEditReferral = true
    },
    closeReferralDialog () {
      this.showAddReferral = false
    },
    closeEditReferral() {
      this.showEditReferral = false
    },
    handleDelete () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.searchFrom > .el-form-item {
  margin-right: 7px !important;
}
</style>
