<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="50%"
    :close-on-click-modal="false"
    @close="handleClose">
    <div class="whole">
      <div class="left">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input v-model="form.account"></el-input>
          </el-form-item>
          <el-form-item label="期望转诊时间">
            <el-date-picker
              v-model="form.value1"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="right"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: true,
      form: {
        name: '',
        account: '',
        value1: ''
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('closeReferralDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.whole {
  display: flex;
  justify-content: space-between;
}
.left {
  flex: 0.9;
}
.right {
  width: 80px;
  height: 150px;
  border: 1px solid #999;
}
</style>