<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="50%"
    :close-on-click-modal="false"
    @close="handleClose">
      <div>
        <div class="twoButton">
          <div @click="changeType(1)">当前任务</div>
          <div @click="changeType(2)">历史进度</div>
        </div>
        <div v-show="type == 1">
          <div class="pic">
            <img src="https://img-blog.csdnimg.cn/img_convert/94f5bc7758cfa4f973dc5087727a9064.png" alt="">
          </div>
          <div>
            <!-- <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="姓名:">
                {{ name }}
              </el-form-item>
              <el-form-item label="客户电话:">
              </el-form-item>
              <el-form-item label="期望转诊时间:">
              </el-form-item>
            </el-form>
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="机构:">
              </el-form-item>
              <el-form-item label="申请时间:">
              </el-form-item>
              <el-form-item label="剩余配额:">
              </el-form-item>
            </el-form> -->
            
            <el-form ref="form" :model="form" label-width="100px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="姓名:">

                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="客户电话:">

                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="期望转诊时间:">

                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="机构:">

                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请时间:">

                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="剩余配额:">

                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="任务状态">
                    <el-select v-model="value" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            
          </div>
        </div>
        <div v-show="type == 2">
          <el-steps :active="1">
            <el-step title="2021-7-1" description="客户申请"></el-step>
            <el-step title="2021-7-2" description="已预约"></el-step>
            <el-step title="2021-7-3" description="已完成"></el-step>
          </el-steps>
        </div>
      </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: true,
      type: 1,
      form: {
      },
      name: '111',
      options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        value: ''
    }
  },
  methods: {
    handleClose(){
      this.$emit('closeEditReferral')
    },
    changeType(val){
      this.type = val
    }
  }
}
</script>

<style lang="scss" scoped>
.twoButton {
  display: flex;
  justify-content: flex-start;
  :first-child {
    margin-right: 10px;
  }
  div {
    padding: 10px 35px;
    border: 1px solid #999;
  }
}
img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 20px 0px;
}
.el-select {
  width: 100%;
}
.el-steps {
  margin-top: 30px;
}
</style>